function videoHandler(event, videoContainer, video, button) {

    if (event.type === 'playing') {
        video.setAttribute("controls", "controls");
        videoContainer.setAttribute('data-video-playing', '');
        if (button.dataset.buttonActive === 'true') button.dataset.buttonActive = 'false';
    } else if (event.type === 'pause') {
        videoContainer.removeAttribute('data-video-playing');
        video.removeAttribute("controls");
        if (button.dataset.buttonActive === 'false') button.dataset.buttonActive = 'true';
    }
}

function toggleVideo(videoContainer, video) {
    if (video.paused) {
        videoContainer.setAttribute('data-video-playing', '');
        video.play();
    } else {
        videoContainer.removeAttribute('data-video-playing');
        video.pause();
    }
}

export function INIT_VIDEO(videoContainer) {
    const video = videoContainer.querySelector('video');
    const videoButton = videoContainer.querySelector('button.is--play');

    if (video && videoButton) {
        video.addEventListener('playing', e => {
            videoHandler(e, videoContainer, video, videoButton)
        }, false);

        video.addEventListener('pause', e => {
            videoHandler(e, videoContainer, video, videoButton)
        }, false);

        video.addEventListener('click', e => {
            e.preventDefault();
            toggleVideo(videoContainer, video);
        });

        videoButton.addEventListener('click', e => {
            e.preventDefault();
            video.play();
        })
    }
}
